import axiosInstance from "../components/auth/interceptor";
import { ReadBanner } from "../interfaces/core/ReadBanner";
import { Basket } from "../interfaces/core/Basket";
import { BasketItem } from "../interfaces/core/BasketItem";
import { BestSellProduct } from "../interfaces/core/BestSellProduct";
import { Offer, OfferResponse } from "../interfaces/core/Offer";
import { OfferItemResponse } from "../interfaces/core/OfferItem";
import { RecentVehicleSearch } from "../interfaces/core/RecentVehicleSearch";
import { Favorite } from "../interfaces/core/Favorite";
import { FavoriteItemCreate } from "../interfaces/core/FavoriteItemCreate";
import { Voucher } from "../interfaces/core/Voucher";
import { CreateOrder } from "../interfaces/core/CreateOrder";
import { ArticlesResponse } from "../interfaces/ArticlesResponse";
import { DashboardResponse } from "../interfaces/core/BashboardResponse";
import { OrdersResponse } from "../interfaces/core/OrdersResponse";
import { UpdateOrder } from "../interfaces/core/UpdateOrder";
import { UsersResponse } from "../interfaces/core/UsersResponse";
import { UserRead } from "../interfaces/core/UserRead";
import { ReturnAndClaimResponse } from "../interfaces/core/ReturnAndClaimResponse";
import { OrdersIdResponse } from "../interfaces/core/OrdersId";
import { UpdateReturnAndClaim } from "../interfaces/core/UpdateReturnAndClaim";
import { UsersReportResponse } from "../interfaces/core/UsersReportResponse";
import { ArticlesReportResponse } from "../interfaces/core/ArticleReportResponse";
import { BannerNotification } from "../interfaces/core/BannerNotification";
import { MainNotification } from "../interfaces/core/MainNotification";
import { VouchersResponse } from "../interfaces/core/VouchersResponse";

const baseURL = process.env.REACT_APP_API_URL;
const priceURL = process.env.REACT_APP_API_PRICE;
export async function storeSearchVehicle(searchVehicle: any): Promise<any> {
  const response = await axiosInstance.post<any>(
    `${baseURL}/recent-vehicles`,
    searchVehicle
  );
  return response.data;
}

export async function getRecentVehiclesSearch(): Promise<
  RecentVehicleSearch[]
> {
  const response = await axiosInstance.get(
    `${baseURL}/recent-vehicles`
  );
  return response.data.recent_vehicles as RecentVehicleSearch[];
}

export async function storeSearchArticle(searchArticle: any): Promise<any> {
  const response = await axiosInstance.post<any>(
    `${baseURL}/recent-articles`,
    searchArticle
  );
  return response.data;
}

export async function fetchLastSearchedArticles() {
  const response = await axiosInstance.get(
    `${baseURL}/recent-articles`
  );
  return response.data.recent_articles;
}

export async function getBanners(): Promise<ReadBanner[]> {
  const response = await axiosInstance.get(`${baseURL}/banners`);
  return response.data as ReadBanner[];
}

export async function getBestSellProducts(): Promise<BestSellProduct[]> {
  const response = await axiosInstance.get(
    `${baseURL}/most-selling-products`
  );
  return response.data as BestSellProduct[];
}


export async function addProductToBasket(
  user_id: number,
  product: BasketItem
): Promise<BasketItem> {
  const response = await axiosInstance.post(
    `${baseURL}/baskets/${user_id}/items`,
    product
  );
  return response.data as BasketItem;
}

export async function getBasketProducts(): Promise<Basket> {
  const response = await axiosInstance.get(`${baseURL}/baskets/`);
  return response.data as Basket;
}

export async function deleteBasketProducts(
  user_id: number,
  ids: number[]
): Promise<Basket> {
  const response = await axiosInstance.delete(
    `${baseURL}/baskets/${user_id}/delete/items`,
    { data: { items_id: ids } }
  );
  return response.data as Basket;
}

export async function getTyreParams(): Promise<any> {
  const response = await axiosInstance.get(`${baseURL}/tyre-params`);
  return response.data;
}

export async function getBatteryParams(): Promise<any> {
   const response = await axiosInstance.get(`${baseURL}/battery-params`);
   return response.data;
}

export async function getUserBalance(options: any) {
  const {clientID, startDate, endDate} = options;
  // Initialize an array to hold query parameters
  const params: string[] = [];

  let url = `${baseURL}/get-client-balance`;

  if (clientID !== null && clientID !== undefined && clientID !== "") {
    params.push(`clientID=${clientID}`);
  }

  if (startDate !== null && startDate !== undefined && startDate !== "") {
    params.push(`startDate=${startDate}`);
  }

  if (endDate !== null && endDate !== undefined && endDate !== "") {
    params.push(`endDate=${endDate}`);
  }
  if (params.length > 0) {
    url += `?${params.join("&")}`;
 }
  
  const response = await axiosInstance.get(url);
  return response.data;
}

export async function getBatteryProducts(options: any) {
   const {ampher, brand, page_number} = options;
   const params: string[] = [];

   let url = `${baseURL}/search-battery`;

   if (ampher !== null && ampher !== undefined && ampher !== "") {
      params.push(`ampher=${ampher}`);
    }   

    if (brand !== null && brand !== undefined && brand !== "") {
      params.push(`brand=${brand}`);
    }   

    if (page_number !== null && page_number !== undefined && page_number !== "") {
      params.push(`page_number=${page_number}`);
    }else {
      params.push(`page_number=1`); // default page
    }

    if (params.length > 0) {
      url += `?${params.join("&")}`;
   }
    
    const response = await axiosInstance.get(url);
    return response.data;
}

export async function getLocalProductByInternalNumber(options: any) {
   const {internalNumber, brand} = options;
   const params: string[] = [];

   let url = `${baseURL}/local-product`;

   if (internalNumber !== null && internalNumber !== undefined && internalNumber !== "") {
      params.push(`keyword=${internalNumber}`);
    }   

    if (brand !== null && brand !== undefined && brand !== "") {
      params.push(`brand=${brand}`);
    }   

    // If there are any params, append them to the URL
   if (params.length > 0) {
      url += `?${params.join("&")}`;
   }
    
    const response = await axiosInstance.get(url);
    return response.data;

}

export async function getTyres(options: any) {
  const { season, width, height, dimension, manufacturer } = options;
  // Initialize an array to hold query parameters
  const params: string[] = [];

  let url = `${baseURL}/search-tyres`;

  if (season !== null && season !== undefined && season !== "") {
    params.push(`season=${season}`);
  }

  if (width !== null && width !== undefined && width !== "") {
    params.push(`width=${width}`);
  }

  if (height !== null && height !== undefined && height !== "") {
    params.push(`height=${height}`);
  }

  if (dimension !== null && dimension !== undefined && dimension !== "") {
    params.push(`dimension=${dimension}`);
  }

  if (
    manufacturer !== null &&
    manufacturer !== undefined &&
    manufacturer !== ""
  ) {
    params.push(`manufacturer=${manufacturer}`);
  }

  if (params.length > 0) {
    url += `?${params.join("&")}`;
  }

  const response = await axiosInstance.get(url);
  return response.data;
}

export async function createOffer(offer: Offer) {
  const response = await axiosInstance.post(
    `${baseURL}/offer`,
    offer
  );
  return response.status;
}

export async function getOffers(options: any): Promise<OfferResponse> {
  const { full_name, page_number } = options;
  // Initialize an array to hold query parameters
  const params: string[] = [];

  let url = `${baseURL}/offers`;

  if (full_name !== null && full_name !== undefined && full_name !== "") {
    params.push(`full_name=${full_name}`);
  }

  if (page_number !== null && page_number !== undefined && page_number !== "") {
    params.push(`page_number=${page_number}`);
  }else {
    params.push(`page_number=1`); // default page
  }
  
  if (params.length > 0) {
    url += `?${params.join("&")}`;
  }
  const response = await axiosInstance.get(url);
  return response.data as OfferResponse;
}

export async function deleteOffers(offerIds: number[]) {
  const response = await axiosInstance.delete(`${baseURL}/offer`, {
    data: { document_nos: offerIds },
  });
  return response.status;
}

export async function createOfferItem(offerItem: any): Promise<any> {
  const response = await axiosInstance.post(`${baseURL}/offer-item`, offerItem);
  return response.data;
}

export async function getOfferItems(): Promise<OfferItemResponse> {
  const response = await axiosInstance.get(`${baseURL}/offer-items`);
  return response.data as OfferItemResponse;
}

export async function deleteOfferItems(offerItemsId: number[]) {
  const response = await axiosInstance.delete(`${baseURL}/offer-items`,{ data: { offerItemIds: offerItemsId } });
  return response.status;
}

export async function addFavoriteItem(
  favoriteItem: FavoriteItemCreate
): Promise<FavoriteItemCreate> {
  const response = await axiosInstance.post(
    `${baseURL}/favorite-item`,
    favoriteItem
  );
  return response.data as FavoriteItemCreate;
}

export async function checkFavoriteItem(id: number): Promise<any> {
  const response = await axiosInstance.get(
    `${baseURL}/check-fav-item/${id}`
  );
  return response.data;
}

export async function getFavoriteProducts(options: any): Promise<Favorite> {
  const { page_number } = options;
  // Initialize an array to hold query parameters
  const params: string[] = [];

  let url = `${baseURL}/favorites`;

  if (page_number !== null && page_number !== undefined && page_number !== "") {
    params.push(`page_number=${page_number}`);
  }else {
    params.push(`page_number=1`); // default page
  }
    
  if (params.length > 0) {
    url += `?${params.join("&")}`;
  }

  const response = await axiosInstance.get(url);
  return response.data as Favorite;
}

export async function deleteFavoriteItems(favoriteItemsId: number[]) {
  const response = await axiosInstance.delete(
    `${baseURL}/favorite-items`,
    { data: { favoriteItemIds: favoriteItemsId } }
  );
  return response.status;
}

export async function getVoucher(code: string): Promise<Voucher> {
  const response = await axiosInstance.get(
    `${baseURL}/voucher/${code}`
  );
  return response.data as Voucher;
}

export async function createOrder(newOrder: CreateOrder): Promise<CreateOrder> {
  const response = await axiosInstance.post(
    `${baseURL}/order`,
    newOrder
  );
  return response.data;
}

export async function getOilParams(options: any) {
  const {
    linkageTargetType,
    linkageTargetId,
    viscosity,
    manufacturer,
    criteria,
    page,
  } = options;
  // Initialize an array to hold query parameters
  const params: string[] = [];

  let url = `${baseURL}/oil-params`;

  if (viscosity !== null && viscosity !== undefined && viscosity !== "") {
    params.push(`viscosity=${viscosity}`);
  }

  if (linkageTargetType !== null && linkageTargetType !== undefined) {
    params.push(`linkageTargetType=${linkageTargetType}`);
  }

  if (linkageTargetId !== null && linkageTargetId !== undefined) {
    params.push(`linkageTargetId=${linkageTargetId}`);
  }

  if (
    manufacturer !== null &&
    manufacturer !== undefined &&
    manufacturer !== ""
  ) {
    params.push(`manufacturer=${manufacturer}`);
  }

  // Add criteria if it is not null, undefined, or empty
  if (criteria && criteria.length > 0) {
    const criteriaParams = criteria
      .map(
        (criterion: { criteriaId: number; rawValue: string }) =>
          `criteria=${criterion.criteriaId}:${encodeURIComponent(
            criterion.rawValue
          )}`
      )
      .join("&");
    params.push(criteriaParams);
  }

  // Add the page parameter, with a default value if not provided
  if (page !== null && page !== undefined) {
    params.push(`page=${page}`);
  } else {
    params.push(`page=1`); // default page
  }

  if (params.length > 0) {
    url += `?${params.join("&")}`;
  }

  const response = await axiosInstance.get(url);
  return response.data;
}

export async function getOilData(options: any): Promise <ArticlesResponse> {
   const {
     linkageTargetType,
     linkageTargetId,
     viscosity,
     assemblyGroupNodeIds,
     category,
     manufacturer,
     criteria,
     page,
   } = options;
   // Initialize an array to hold query parameters
   const params: string[] = [];
 
   let url = `${baseURL}/search-oil`;
 
   if (viscosity !== null && viscosity !== undefined && viscosity !== "") {
     params.push(`viscosity=${viscosity}`);
   }
 
   if (linkageTargetType !== null && linkageTargetType !== undefined) {
     params.push(`linkageTargetType=${linkageTargetType}`);
   }
 
   if (linkageTargetId !== null && linkageTargetId !== undefined) {
     params.push(`linkageTargetId=${linkageTargetId}`);
   }

   if (assemblyGroupNodeIds !== null && assemblyGroupNodeIds !== undefined) {
      params.push(`assemblyGroupNodeIds=${assemblyGroupNodeIds}`);
    }

   if (category !== null && category !== undefined) {
      params.push(`category=${category}`);
    }
 
   if (
     manufacturer !== null &&
     manufacturer !== undefined &&
     manufacturer !== ""
   ) {
     params.push(`brand=${manufacturer}`);
   }
 
   // Add criteria if it is not null, undefined, or empty
   if (criteria && criteria.length > 0) {
     const criteriaParams = criteria
       .map(
         (criterion: { criteriaId: number; rawValue: string }) =>
           `criteria=${criterion.criteriaId}:${encodeURIComponent(
             criterion.rawValue
           )}`
       )
       .join("&");
     params.push(criteriaParams);
   }
 
   // Add the page parameter, with a default value if not provided
   if (page !== null && page !== undefined) {
     params.push(`page=${page}`);
   } else {
     params.push(`page=1`); // default page
   }
 
   if (params.length > 0) {
     url += `?${params.join("&")}`;
   }
 
   const response = await axiosInstance.get(url);
   return response.data as ArticlesResponse;
 }

 export async function getDashboardData(): Promise <DashboardResponse> {
   const response = await axiosInstance.get(`${baseURL}/dashboard`);
   return response.data as DashboardResponse;
 }

 export async function getOrders(options: any): Promise<OrdersResponse> {
  const { from_date, to_date, page_number } = options;
  // Initialize an array to hold query parameters
  const params: string[] = [];

  let url = `${baseURL}/orders`;

  if (from_date !== null && from_date !== undefined && from_date !== "") {
    params.push(`from_date=${from_date}`);
  }

  if (to_date !== null && to_date !== undefined && to_date !== "") {
    params.push(`to_date=${to_date}`);
  }

  if (page_number !== null && page_number !== undefined && page_number !== "") {
    params.push(`page_number=${page_number}`);
  }else {
    params.push(`page_number=1`); // default page
  }

  if (params.length > 0) {
    url += `?${params.join("&")}`;
  }

  const response = await axiosInstance.get(url);
  return response.data as OrdersResponse;
}

export async function updateOrderVisited(updatedPartialOrder: Partial<UpdateOrder>): Promise<UpdateOrder> {
  const updatedOrder = { id: updatedPartialOrder.id, visited: true, currency: updatedPartialOrder.currency };
  const response = await axiosInstance.patch(`${baseURL}/order/${updatedPartialOrder.id}`, updatedOrder);
  return response.data as UpdateOrder;
}

export async function updateOrderStatus(updatedPartialOrder: Partial<UpdateOrder>, newStatus: string): Promise<UpdateOrder> {
  const updatedOrder = { id: updatedPartialOrder.id, status: newStatus, currency: updatedPartialOrder.currency };
  const response = await axiosInstance.patch(`${baseURL}/order/${updatedPartialOrder.id}`, updatedOrder);
  return response.data as UpdateOrder;
}

export async function createUser(userData: any) {
  const response = await axiosInstance.post(`${baseURL}/users`, userData);
  return response;
}

export async function getUser(id: number): Promise<any> {
  const response = await axiosInstance.get(`${baseURL}/users/${id}`); 
  return response.data;
}

export async function getUsers(options:any): Promise<UsersResponse> {
  const {keyword, page_number} = options;
  const params: string[] = [];
  let url = `${baseURL}/users`;


  if (keyword !== null && keyword !== undefined && keyword !== "") {
    params.push(`keyword=${keyword}`);
  }
    if (page_number !== null && page_number !== undefined && page_number !== "") {
      params.push(`page_number=${page_number}`);
    }else {
      params.push(`page_number=1`); // default page
    }

    if (params.length > 0) {
      url += `?${params.join("&")}`;
    }

    const response = await axiosInstance.get(url);
  return response.data as UsersResponse;

}

export async function updateUser(userData: UserRead) {
  const response = await axiosInstance.patch(`${baseURL}/users`, userData);
  return response;
}

export async function changeGridView() {
  const response = await axiosInstance.put(`${baseURL}/users/grid-view`);
  return response;
}

export async function changeRetailPrice() {
  const response = await axiosInstance.put(`${baseURL}/users/retail-price`);
  return response;
}

export async function getReturnRequests(options: any): Promise<ReturnAndClaimResponse> {
  const { from_date, to_date, page_number } = options;
  // Initialize an array to hold query parameters
  const params: string[] = [];

  let url = `${baseURL}/return-claims`;

  if (from_date !== null && from_date !== undefined && from_date !== "") {
    params.push(`from_date=${from_date}`);
  }

  if (to_date !== null && to_date !== undefined && to_date !== "") {
    params.push(`to_date=${to_date}`);
  }

  if (page_number !== null && page_number !== undefined && page_number !== "") {
    params.push(`page_number=${page_number}`);
  }else {
    params.push(`page_number=1`);
  }

  if (params.length > 0) {
    url += `?${params.join("&")}`;
  }

  const response = await axiosInstance.get(url);
  return response.data as ReturnAndClaimResponse;
}

export async function getOrdersId(): Promise<OrdersIdResponse> {
  const response = await axiosInstance.get(`${baseURL}/orders-id`);
  return response.data as OrdersIdResponse;
}

export async function createReturnItem(returnItemData: any) {
  const response = await axiosInstance.post(`${baseURL}/return-item`, returnItemData);
  return response;
}

export async function getOrderItemByOrderId(orderId: number) {
  const response = await axiosInstance.get(`${baseURL}/order/${orderId}/items`);
  return response.data;
}

export async function createReturnRequest(returnRequest: any) {
  const response = await axiosInstance.post(`${baseURL}/return-claim`,returnRequest);
  return response;
}

export async function updateReturnRequestVisited(updatedPartialReturnRequest: Partial<UpdateReturnAndClaim>): Promise<UpdateReturnAndClaim> {
  const updatedReturnRequest = { id: updatedPartialReturnRequest.id, visited: true, currency: updatedPartialReturnRequest.currency };
  const response = await axiosInstance.patch(`${baseURL}/return-claim/${updatedPartialReturnRequest.id}`, updatedReturnRequest);
  return response.data as UpdateReturnAndClaim;
}

export async function updateReturnRequestStatus(updatedPartialReturnRequest: Partial<UpdateReturnAndClaim>, newStatus: string): Promise<UpdateReturnAndClaim> {
  const updatedReturnRequest = { id: updatedPartialReturnRequest.id, status: newStatus, currency: updatedPartialReturnRequest.currency };
  const response = await axiosInstance.patch(`${baseURL}/return-claim/${updatedPartialReturnRequest.id}`, updatedReturnRequest);
  return response.data as UpdateReturnAndClaim;
}



export async function getUsersReport(options:any): Promise<UsersReportResponse> {
  const { keyword, from_date, to_date, page_number } = options;
  const params: string[] = [];

  let url = `${baseURL}/users-report`;

  if (keyword !== null && keyword !== undefined && keyword !== ""){
    params.push(`keyword=${keyword}`);
  }

  if (from_date !== null && from_date !== undefined && from_date !== "") {
    params.push(`from_date=${from_date}`);
  }

  if (to_date !== null && to_date !== undefined && to_date !== "") {
    params.push(`to_date=${to_date}`);
  }

  if (page_number !== null && page_number !== undefined && page_number !== "") {
    params.push(`page_number=${page_number}`);
  }else {
    params.push(`page_number=1`);
  }

  if (params.length > 0) {
    url += `?${params.join("&")}`;
  }

  const response = await axiosInstance.get(url);
  return response.data as UsersReportResponse;

}

export async function getArticlesReport(options:any): Promise<ArticlesReportResponse> {
  const { keyword, from_date, to_date, page_number } = options;
  const params: string[] = [];

  let url = `${baseURL}/articles-report`;

  if (keyword !== null && keyword !== undefined && keyword !== ""){
    params.push(`keyword=${keyword}`);
  }
  
  if (from_date !== null && from_date !== undefined && from_date !== "") {
    params.push(`from_date=${from_date}`);
  }

  if (to_date !== null && to_date !== undefined && to_date !== "") {
    params.push(`to_date=${to_date}`);
  }

  if (page_number !== null && page_number !== undefined && page_number !== "") {
    params.push(`page_number=${page_number}`);
  }else {
    params.push(`page_number=1`);
  }

  if (params.length > 0) {
    url += `?${params.join("&")}`;
  }

  const response = await axiosInstance.get(url);
  return response.data as ArticlesReportResponse;

}

export async function createBannerNotification(bannerNotification: any) {
  const response = await axiosInstance.post(`${baseURL}/banner-notification`,bannerNotification);
  return response;
}

export async function getBannerNotification(): Promise <BannerNotification> {
  const response = await axiosInstance.get(`${baseURL}/banner-notification`);
  return response.data as BannerNotification;
}


export async function createNotification(mainNotification: any): Promise<MainNotification> {
  const response = await axiosInstance.post(`${baseURL}/main-notification`,mainNotification);
  return response.data as MainNotification;
}

export async function getNotifications(): Promise <MainNotification[]> {
  const response = await axiosInstance.get(`${baseURL}/main-notifications`);
  return response.data.notifications as MainNotification[];
}

export async function uploadBanner(formData: any) {
  const response = await axiosInstance.post(`${baseURL}/banner/uploadfile/`, formData);
  return response;
}

export async function deleteBanner(id: any) {
  const response = await axiosInstance.delete(`${baseURL}/banner/${id}`);
  return response;
}


export async function getVouchers(options:any): Promise<VouchersResponse> {
  const {keyword, page_number} = options;
  const params: string[] = [];
  let url = `${baseURL}/vouchers`;


  if (keyword !== null && keyword !== undefined && keyword !== "") {
    params.push(`keyword=${keyword}`);
  }
    if (page_number !== null && page_number !== undefined && page_number !== "") {
      params.push(`page_number=${page_number}`);
    }else {
      params.push(`page_number=1`); // default page
    }

    if (params.length > 0) {
      url += `?${params.join("&")}`;
    }

    const response = await axiosInstance.get(url);
  return response.data as VouchersResponse;
}

export async function updateVoucher(voucherData: Voucher) {
  const code = voucherData.code;
  const response = await axiosInstance.patch(`${baseURL}/voucher/${code}`, voucherData);
  return response;
}

export async function createVoucher(voucherData: any) {
  const response = await axiosInstance.post(`${baseURL}/voucher`, voucherData);
  return response;
}

export async function deleteVoucher(code: string) {
  const response = await axiosInstance.delete(`${baseURL}/voucher/${code}`);
  return response;
}

export async function getLiveArticleState(erpId: number, signal?: AbortSignal) {
  const response = await axiosInstance.get(`${baseURL}/live_article_state/${erpId}`, {
    cache: {
      ttl: 1000 * 600, // 10 minutes
    },
    signal,
  });

  return response.data;
}